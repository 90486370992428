<template>
    <div class="walk-container">
        <walk-card :list="walkingList" imgTag="rwImage"></walk-card>
    </div>
</template>

<script>
import { getwalkingList } from "@/service";
import walkCard from "./components/walk-card.vue";
export default {
    components: {
        walkCard,
    },
    data() {
        return {
            walkingList: [],
        };
    },
    mounted() {
        this.getwalkingList();
    },
    methods: {
        async getwalkingList() {
            const params = {
                pageNo: 1,
                pageSize: 1000,
            };
            const { data } = await getwalkingList(params);
            this.walkingList = data.list;
        },
    },
};
</script>

<style lang="scss" scoped>
.walk-container {
    padding: 40px 36px;
}
</style>