<template>
    <nav class="punch-card">
        <div class="item-container" v-if="list.length">
            <div v-for="(item, index) in list" :key="index">
                <router-link
                    :to="{ path: '/walk/details', query: { id: item.id } }"
                >
                    <div class="item-card">
                        <!-- <img :src="item[imgTag]" /> -->
                        <!-- <div
                            class="punch-img"
                            :style="{
                                background: `url(${item[imgTag]})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center'
                            }"
                        ></div> -->
                        <van-image class="punch-img" :src="item[imgTag]" fit="cover" radius="10 20" />
                        <div class="punch-content">
                            <figure>
                                <div class="punch-title">{{ item.title }}</div>
                                <div class="punch-label u-m-t-10">
                                    {{ item.abbreviation }}
                                </div>
                                <figcaption>
                                    <div class="punch-time">
                                        {{ item.createdAt }}
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "punch-card",
    props: ["list", "imgBaseUrl", "imgTag"],
    components: {},
    data() {
        return {};
    },
    methods: {
        getCategoryId() {},
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_varibles";
@import "@/style/mixin";

.punch-card {
    width: 100%;
    background-color: #fff;
    .item-container {
        @include wh(100%, auto);
        .item-card {
            @include fj(center);
            width: 100%;
            text-align: center;
            margin-top: 30px;
            border-radius: 20px;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
            img {
                flex: 1;
                @include wh(100%, 280px);
                border-radius: 20px 0 0 20px;
            }
            .punch-img {
                @include wh(100%, 320px);
                border-radius: 20px 0 0 20px;
                flex: 1;
            }
            .punch-content {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                figure {
                    width: 100%;
                    padding-left: 20px;
                    text-align: left;
                    .punch-title {
                        font-weight: 500;
                        @include sc(24px, $--color-text-regular);
                        width: 90%;
                        white-space: normal;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        /*! autoprefixer: off */
                        -webkit-box-orient: vertical;
                        /* autoprefixer: on */
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        word-break: break-all; /*追加这一行代码*/
                    }
                    .punch-label {
                        @include sc(20px, $--color-text-secondary);
                        width: 90%;
                        white-space: normal;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        /*! autoprefixer: off */
                        -webkit-box-orient: vertical;
                        /* autoprefixer: on */
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        word-break: break-all; /*追加这一行代码*/
                    }
                    .punch-time {
                        @include sc(20px, #999);
                        overflow-wrap: break-word;
                        white-space: nowrap;
                        line-height: 28px;
                        white-space: normal;
                        text-align: left;
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}
</style>